import { AFFILIATE_IDS, CHANNEL_IDS, USER_IDS, API_RETRY_MAX, API_RETRY_DELAY } from '~/constants/config/common';
import { setNewRelicCustomAttribute, setNewRelicNoticeError } from '~/helpers/newrelic';
import { BaseResponse } from '~/types/common';
import clientSide from './clientSide';
import { apiErrorTest, apiErrorTracking } from './apiErrorTest';
import merge from 'lodash/merge';
import config from '~/constants/config';
import { trackEvent } from './tracking';
import { ReduxState } from '~/types/redux';
import { Dispatch } from 'redux';
import { handleExpiredSession } from './sessionExpirationError';

type Tracking = Record<string, string>;

interface FetchUrlParams {
  endpoint: string;
  options: RequestInit;
  tracking?: Tracking;
  monarch?: ReduxState['monarch'];
  dispatch?: Dispatch;
}

interface ErrorMessage {
  success?: boolean;
  errorMessage?: string;
  frapiErrorCode?: string;
}

const getBrowserPath = () => clientSide && window.location && window.location.pathname;

export const getHeaders = (tracking?: Tracking, options?: RequestInit): HeadersInit => {
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (tracking) {
    headers = {
      ...headers,
      quoteid: tracking?.quoteId || '',
      orderId: tracking?.orderId || '',
      'x-tenant-id': tracking?.tenantId || '',
      'x-client-id': tracking?.clientId || '',
      'x-client-session-id': tracking?.clientSessionId || '',
      'x-cohesion-anonymous-id': tracking?.anonymousId || '',
      'x-cohesion-session-id': tracking?.sessionId || '',
      'x-correlation-id': tracking?.correlationId || '',
      'x-write-key': tracking?.writeKey || '',
      'x-affiliate-id': tracking?.affiliateId || AFFILIATE_IDS.rvfrdccart,
      'x-channel-id': tracking?.channelId || CHANNEL_IDS.rvfrdccart,
      'x-user-id': tracking?.userId || USER_IDS.FRDC,
      siteKey: tracking?.siteKey || '',
      monarchSourceId: tracking?.monarchSourceId || '',
      monarchToken: tracking?.monarchToken || '',
      'x-site-path': getBrowserPath() || '',
      retryAttempts: tracking?.retryAttempts || '0',
    };
  }

  return {
    ...headers,
    ...(options?.headers || {}),
  };
};

let errorResponse: ErrorMessage = { success: false } || {};

const fetchUrl = async <T extends BaseResponse>({
  endpoint,
  options,
  tracking,
  monarch,
  dispatch,
}: FetchUrlParams): Promise<T> => {
  const opts = {
    ...options,
    headers: getHeaders(tracking, options),
  };

  let retryCount = 0;

  const fetchData = async (): Promise<T> => {
    const retryHeaders = { headers: { retryAttempts: retryCount } };
    let data: T;
    try {
      const response = await fetch(`${config.api}${endpoint}${apiErrorTest('?')}`, merge({}, opts, retryHeaders));
      const data: T = await response?.json();

      if (response.ok) {
        if (data?.errorMessage) throw new Error(data?.errorMessage);
        if (retryCount) {
          apiErrorTracking({ url: response.url, retryCount, success: true });
          setNewRelicCustomAttribute([{ name: 'retryAttempts', value: retryCount }]);
          retryCount++;
        }
        return data;
      } else {
        handleExpiredSession({ monarch, dispatch, frapiErrorCode: data?.frapiErrorCode });
        if (retryCount < API_RETRY_MAX && data?.isHardStop) {
          const delay = Math.pow(2, retryCount) * API_RETRY_DELAY; // Exponential backoff delay
          await new Promise((resolve) => setTimeout(resolve, delay));
          apiErrorTracking({ url: response.url, retryCount, success: false });
          setNewRelicCustomAttribute([{ name: 'retryAttempts', value: retryCount }]);
          retryCount++;
          return fetchData();
        } else {
          // update error response to include the error message + frapiErrorCode before throwing the error
          if (data?.errorMessage) {
            errorResponse = {
              success: false,
              errorMessage: data?.errorMessage,
              frapiErrorCode: data?.frapiErrorCode,
            };
            trackEvent({
              action: 'clientErrored',
              data: {
                errorMessage: data?.errorMessage,
                errorClass: 'ERROR',
              },
            });
            throw new Error(data?.errorMessage);
          } else {
            throw new Error(
              `Partner API Failure: status ${response.status} - ${response.url}, no error message found.`
            );
          }
        }
      }
    } catch (error) {
      console.log(error); // Log for LogRocket debugging
      setNewRelicNoticeError(error);
      trackEvent({
        action: 'clientErrored',
        data: {
          errorMessage: error?.message,
          errorClass: error?.name,
        },
      });
      // If possible, merge the full response data with the base error response
      return merge(data || {}, errorResponse) as T;
    }
  };

  return fetchData();
};

export default fetchUrl;
